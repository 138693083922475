// This stylesheet is used for the Editor document preview and the rendered PDF
@import 'snippet';
$header-height: 80px;

.document {
  font-family: $font-family;
  font-weight: 400;

  text-rendering: optimizeLegibility;
  font-size: 12pt;
  line-height: 1.5;

  .document-header {
    position: relative;
    //margin-bottom: -10px;
    padding-top: 10px;

    h1 {
      font-size: 18pt;
      font-weight: 700;
    }
  }

  .document-header > div {
    align-items: center; /* For vertical align */
    display: flex;
    height: $header-height;
    margin-bottom: 5px;
    padding: 0 0 5px 0;
  }

  .document-header-logo {
    float: right;
    text-align: right;
    margin-left: auto;

    img {
      max-width: 200px;
      max-height: $header-height;
    }
  }

  .document-header-caption {
    line-height: 1.3;
    font-weight: 400;
    display: block;
    font-size: 14pt;
    color: #6f777b;
  }

  .document-header-hr {
    display: block;
    box-sizing: border-box; //for consistency with screen version
    height: 1px;
    border: 0;
    border-top: 10px solid #CCC;
    margin-top: 2px;
    padding: 0;
  }

  .document-title {
    font-size: 24pt;
    margin-top: 10mm;
    margin-bottom: 5mm;
    font-weight: 700;
    letter-spacing: 0.01em;
  }

  .document-body {
    width: 75%;
    @extend .snippet;
  }

  .disclaimer {
    font-size: 12pt;
    border-top: 1px solid #bfc1c3;
    margin-top: 25px;
    padding-top: 25px;
  }

  .document-footer {
    @import '~govuk-frontend/govuk/components/table/table';
  }
}

// Large text style sheet
.document.large-text {
  font-size: 1.2em;

  table {
    font-size: 1.2em;
  }
}
